import React from "react"
import { navigate } from "gatsby"
export default function NordicRenewable() {
  return (
    <div>
      {" "}
      <form
        action="https://getform.io/f/7682a439-1aa1-4a23-ab13-8f4bedef2616"
        method="POST"
        className="form"
      >
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="name"
          >
            Your Name<span className="text-red-500">*</span>
          </label>
          <input type="text" name="name" id="name" required />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="email"
          >
            Your Email<span className="text-red-500">*</span>
          </label>
          <input name="email" id="email" type="email" required />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="companyname"
          >
            Company Name<span className="text-red-500">*</span>
          </label>
          <input type="text" name="companyname" id="companyname" required />
        </div>
        <div className="">
          <p className="pb-4" style={{ display: `block`, marginBottom: `4px` }}>
            Participant fees depend on your company´s client-relation with
            Bodecker Partners. Invoices will be sent out about two weeks before
            the event. Please accept fee and list relevant category.
            <span className="text-red-500">*</span>
          </p>
          <div className="flex flex-row items-center space-x-4">
            <input
              type="radio"
              id="portfolio-client"
              name="relation"
              value="Portfolio management client"
              required
            />
            <label for="portfolio-client">
              Porfolio management client (150 euros / participant)
            </label>
          </div>

          <div className="flex flex-row items-center space-x-4">
            <input
              type="radio"
              id="other-client"
              name="relation"
              value="Other client"
            />
            <label for="other-client">
              Other client or Subscriber to Nordic Renewables Report (300 euros
              / participant)
            </label>
          </div>

          <div className="flex flex-row items-center space-x-4">
            <input
              type="radio"
              id="non-client"
              name="relation"
              value="Non client"
            />
            <label for="non-client">Non-client (550 euros / participant)</label>
          </div>
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="foodallergies"
          >
            Food Allergies or preferences?
          </label>
          <input type="text" name="foodallergies" id="foodallergies" />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="vat"
          >
            VAT-number (for invoicing)<span className="text-red-500">*</span>
          </label>
          <input type="text" name="vat" id="vat" required />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="companyinvoicename"
          >
            Company name for invoicing (if other than Company name in
            registration)
          </label>
          <input
            type="text"
            name="companyinvoicename"
            id="companyinvoicename"
          />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="invoiceemail"
          >
            E-mail for invoicing<span className="text-red-500">*</span>
          </label>
          <input type="text" name="invoiceemail" id="invoiceemail" required />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="companyaddress"
          >
            Company address<span className="text-red-500">*</span>
          </label>
          <textarea name="companyaddress" id="companyaddress" required />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="otherinvoiceinfo"
          >
            Other invoicing information if needed (reference etc.)
          </label>
          <textarea name="otherinvoiceinfo" id="otherinvoiceinfo" />
        </div>
        <div>
          <label
            style={{ display: `block`, marginBottom: `4px` }}
            htmlFor="extrainfo"
          >
            Other information you want to add?
          </label>
          <textarea name="extrainfo" id="extrainfo" />
        </div>

        <div>
          <button className="block bg-primary rounded-lg px-4 py-2 text-xs tracking-wider uppercase mt-4 ">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}
