import React from "react"
import NordicRenewable from "../../components/forms/NordicRenewable"
import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/common/PageHeader"
import Seo from "../../components/seo"

export default function NordicRenewablePage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 537 }) {
        id
        content
        title
      }
    }
  `)
  const { title, content } = data?.wpPage || "Title"
  return (
    <Layout>
      <Seo title={title} />

      <div className="space-y-4 custom-form">
        <div>
          <PageHeader text={title} />
        </div>
        <div className="pb-4">
          {" "}
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="wp-content utskick"
          />
        </div>
        <div className="bg-third p-8 mt-4">
          <NordicRenewable />
        </div>
      </div>
    </Layout>
  )
}
